import * as z from 'myzod'

export const ConfigAwsSchema = z
  .object(
    {
      aws_project_region: z.string(),
      aws_cognito_identity_pool_id: z.string(),
      aws_cognito_region: z.string(),
      aws_user_pools_id: z.string(),
      aws_user_pools_web_client_id: z.string(),
      oauth: z.object({ aws_cognito_domain: z.string().optional() }).optional(),
      aws_cognito_use_federated_sign_in: z.boolean().default(false),
      aws_cognito_federated_sign_in_provider: z
        .union([z.literal('CustomOIDC'), z.literal('SAML')])
        .default('CustomOIDC'),
      aws_cognito_use_user_pass_auth: z.boolean().default(true),
    },
    { allowUnknown: true }
  )
  .default({
    aws_project_region: 'undefined',
    aws_cognito_identity_pool_id: 'undefined',
    aws_cognito_region: 'undefined',
    aws_user_pools_id: 'undefined',
    aws_user_pools_web_client_id: 'undefined',
    aws_cognito_use_federated_sign_in: false,
    aws_cognito_federated_sign_in_provider: 'CustomOIDC',
    aws_cognito_use_user_pass_auth: true,
  })

export type ConfigAws = z.Infer<typeof ConfigAwsSchema>
